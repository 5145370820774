<template>
  <div>
    <!-- MODAL AREA -->
    <b-modal
      id="addModals"
      ref="addModalForm"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="lg"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">
          {{ req_type }} Form
        </h3>
      </div>
      <validation-observer
        v-if="!isLoading"
        ref="customerTitle"
        tag="form"
        @submit.prevent="submitAdd"
      >
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Title">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="Title"
                    v-model="formData.title"
                    placeholder="Title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group label="Application">
                <validation-provider
                  #default="{ errors }"
                  name="Application"
                  rules="required"
                >
                  <model-select
                    v-model="formData.application_id"
                    :options="listapplication"
                    placeholder="-- Please select --"
                    :state="errors.length > 0 ? false : null"
                    @input="get_application_id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="showPurpose">
            <b-col md="12">
              <b-form-group label="Purpose">
                <validation-provider
                  #default="{ errors }"
                  name="Purpose"
                  rules="required"
                >
                  <model-select
                    v-model="formData.application_purpose"
                    :options="listapplicationPurpose"
                    placeholder="-- Please select --"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group label="Description">
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="formData.description"
                    :options="editorOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Due Date">
                <validation-provider
                  #default="{ errors }"
                  name="Due Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="formData.due_date"
                    :config="flatpickconfig"
                    :class="errors.length === 0
                      ? 'form-control'
                      : 'form-control is-invalid'
                    "
                    placeholder="Due Date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Priority">
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <model-select
                    v-model="formData.priority_status"
                    :options="listPriority"
                    placeholder="-- Please select --"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="CC Email"
                label-for="input-ccto"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email CC"
                  rules="required"
                >
                  <multiselect
                    v-model="selectedCC"
                    :options="employeeEmailList"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    placeholder="Pick Employee To CC Email"
                    label="text"
                    track-by="value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isManager">
            <b-col md="12">
              <b-form-group
                label="Requested By"
                label-for="input-ccto"
              >
                <model-select
                  v-model="formData.requested_by"
                  :options="employeeList"
                  placeholder="-- Please select --"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group label="Attachment">
                <validation-provider
                  #default="{ errors }"
                  name="Attachment"
                  rules=""
                >
                  <vue-dropzone
                    id="fileInputP"
                    ref="myFileP"
                    name="fileInputP"
                    :options="dropzoneOptions"
                    @vdropzone-sending="sendingFileRequestEvent"
                    @vdropzone-complete="completeFileRequestEvent"
                    @vdropzone-success="uploadFileRequestSuccess"
                    @vdropzone-error="errorFileRequestEvent"
                    @vdropzone-removed-file="removeFileRequest"
                  />
                  <small class="text-danger">{{
                    errAttachment.fileInputP != ""
                      ? errAttachment.fileInputP
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <loading v-if="isLoading" />
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="button"
          size="sm"
          variant="danger"
          :disabled="isLoading"
          @click="closeFormRequest"
        ><i class="fa fa-undo" /> Back</b-button>
        &nbsp;
        <b-button
          type="button"
          size="sm"
          variant="primary"
          :disabled="isLoading || submitDisabled"
          @click="submitAdd"
        ><i class="fa fa-save" /> Save</b-button>
      </div>
    </b-modal>
    <!-- MODAL PREVIEW -->
    <b-modal
      id="previewModals"
      ref="previewModal"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      size="xl"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">
          Preview Attachment
        </h3>
      </div>
      <div class="d-flex justify-content-center">
        <img
          v-if="previewType == 'image'"
          :src="previewUrl"
          class="img-fluid"
        >
        <object
          v-if="previewType == 'pdf'"
          :data="previewUrl"
          type="application/pdf"
          width="100%"
          height="600px"
        >
          <p>
            Your web browser doesn't have a PDF plugin.
            <a :href="previewUrl">click here to download the PDF file.</a>
          </p>
        </object>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="button"
          size="sm"
          variant="danger"
          @click="$bvModal.hide('previewModals')"
        ><i class="fa fa-undo" /> Back</b-button>
        &nbsp;
      </div>
    </b-modal>

    <!-- End MODAL AREA -->

    <!-- MODAL PREVIEW COMMENT -->
    <b-modal
      id="previewCommentModals"
      ref="previewCommentModal"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      size="xl"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">{{ (titleCommentHistory !== null) ? titleCommentHistory.title +
          '(' + titleCommentHistory.request_code + ')' : '' }}</h3>
      </div>
      <div
        class="d-flex justify-content-center"
        style="width:fit-content"
      >
        <template v-if="!isLoadingPrivewComment">
          <div
            v-if="listComment.length > 0"
            class="overflow-auto py-1"
            style="max-height: 65vh"
          >
            <app-timeline
              v-for="comment in listComment"
              :key="comment.id"
            >
              <app-timeline-item
                variant="primary"
                :user="`${comment.inserted_by} - ${comment.user_name}`"
                :description="comment.description"
                :time="comment.inserted_time"
                :icon="null"
                :attachment="comment.attachment"
              />
            </app-timeline>
          </div>
          <div v-else>
            <p class="text-center">
              No Comments
            </p>
          </div>
        </template>
        <template v-else>
          <loading />
        </template>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="button"
          size="sm"
          variant="danger"
          @click="$bvModal.hide('previewCommentModals')"
        ><i class="fa fa-undo" /> Close</b-button>
        &nbsp;
      </div>
    </b-modal>

    <!-- MODAL SEARCH -->
    <b-modal
      id="searchModals"
      ref="searchModalForm"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="lg"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">
          Search Form
        </h3>
      </div>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Application">
              <model-select
                v-model="searchingValApp"
                :options="listapplication"
                placeholder="-- Please select --"
                @input="searching"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Requester">
              <model-select
                v-model="searchingValRequester"
                :options="employeeListAll"
                placeholder="-- Please select --"
                @input="searching"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Title">
              <b-form-input
                v-model="searchingValTitle"
                placeholder="Please type your title"
                @blur="searching"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Request Date">
              <flat-pickr
                v-model="searchingValDate"
                :config="flatpickReqconfig"
                placeholder="Request Date"
                @input="searching"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Due Date">
              <flat-pickr
                v-model="searchingValDueDate"
                :config="flatpickReqconfig"
                placeholder="Due Date"
                @input="searching"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Description">
              <b-form-input
                v-model="searchingValDesc"
                placeholder="Please type your description"
                @blur="searching"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="button"
          size="sm"
          variant="danger"
          @click="$bvModal.hide('searchModals')"
        ><i class="fa fa-undo" /> Close</b-button>
        &nbsp;
        <b-button
          type="button"
          size="sm"
          variant="warning"
          @click="resetFilter"
        >
          <i class="fa fa-undo" /> Clear
        </b-button>
      </div>

    </b-modal>
    <!-- END MODAL SEARCH -->

    <!-- End MODAL AREA -->
    <loading v-if="isLoadingMain" />
    <!-- Main Area -->
    <div v-if="!isLoadingMain">
      <b-row>
        <b-col
          md="3"
          lg="3"
          sm="12"
        >
          <b-button
            size="md"
            variant="primary"
            @click.prevent="addFormRequest()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>Add Task</span>
          </b-button>
        </b-col>

        <b-col
          md="6"
          lg="6"
          sm="12"
        >
          <b-form-input
            id="searching"
            v-model="searchingVal"
            name="searching"
            placeholder="Please type your title or request code"
            @blur="searching"
          />
        </b-col>
        <b-col
          md="3"
          lg="3"
          sm="12"
        >
          <b-button
            size="md"
            variant="primary"
            @click.prevent="searchFormRequest()"
          >
            <span>More Filter</span>
          </b-button>
        </b-col>
      </b-row>

      <div class="d-flex flex-column">
        <div>
          <kanban-board
            :stages="stages"
            :blocks="blocks"
            :config="config"
            class="overflow-auto"
            style="max-height: 60vh"
            @update-block="updateBlock"
          >
            <!-- Title Status -->
            <div
              v-for="stage in stages"
              :slot="stage"
              :key="stage"
            >
              <h2>{{ stage }}</h2>
            </div>
            <div
              v-for="block in blocks"
              :id="block.id"
              :slot="block.id"
              :key="block.id"
            >
              <template>
                <div class="d-flex flex-column">
                  <div
                    v-b-tooltip.hover
                    style="margin-bottom: 0.25rem"
                    :title="block.request_code"
                  >
                    <strong>{{ block.title }}</strong>
                  </div>
                  <div class="d-flex justify-content-between flex-wrap">
                    <div>
                      <!-- //if status not finish and due_date is before today, then danger, if due_date is remain 3 days then warning, then warning, else success -->
                      <b-badge
                        v-if="block.status_name !== 'Finish'"
                        v-b-tooltip.hover
                        :variant="`light-${moment(block.due_date).isBefore(moment())
                          ? 'danger'
                          : moment(block.due_date).isBefore(
                            moment().add(3, 'days')
                          )
                            ? 'warning'
                            : 'success'
                        }`"
                        :title="moment(block.due_date).fromNow()"
                      >
                        {{ moment(block.due_date).format("DD MMM YYYY") }}
                      </b-badge>
                      <b-badge
                        v-else
                        v-b-tooltip.hover
                        :variant="`light-success`"
                        :title="moment(block.due_date).format('DD MMM YYYY')"
                      >
                        Done
                        <!-- {{ moment(block.due_date).format('DD MMM YYYY') }} -->
                      </b-badge>
                    </div>
                    <div class="d-flex justify-content-end flex-column flex-xl-row">
                      <b-badge
                        :variant="`light-dark`"
                        style="margin-right: 0.25rem"
                      >
                        {{ block.application_name }}
                      </b-badge>
                      <b-badge
                        v-b-tooltip.hover
                        title="Priority"
                        :variant="`light-${block.priority_color}`"
                        class="mt-1 mt-xl-0"
                      >
                        {{ block.priority }}
                      </b-badge>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center flex-wrap" v-if="block.purpose_name">
                    <div></div>
                    <div class="d-flex justify-content-end flex-column flex-xl-row">
                      <b-badge
                        :variant="`light-info`"
                        style="margin-right: 0.25rem"
                      >
                        {{ block.purpose_name }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </template>
              <template>
                <div class="d-flex flex-column my-2">
                  <div style="margin-top: 0.5rem">
                    <strong>{{ block.subtitle }}</strong>
                  </div>
                  <div
                    v-if="assignedTo[block.id] !== undefined"
                    class="text-right"
                  >
                    <b-avatar-group size="34px">
                      <b-avatar
                        v-for="(item, index) in assignedTo[block.id]"
                        :key="index"
                        v-b-tooltip.hover.bottom="item"
                        :text="avatarText(cleantext(item))"
                        :variant="`light-info`"
                        class="pull-up"
                      />
                    </b-avatar-group>
                  </div>
                </div>
              </template>

              <template>
                <div class="d-flex justify-content-between">
                  <div>
                    <b-nav>
                      <b-nav-item @click="showComment(block.id)">
                        <feather-icon
                          :icon="'MessageSquareIcon'"
                          :badge="block.comment_count"
                          size="18"
                        />
                      </b-nav-item>
                      <b-nav-item
                        v-if="block.attachment"
                        v-b-tooltip.hover
                        title="View Attachment"
                        @click="attachment(block.attachment)"
                      >
                        <feather-icon
                          :icon="'PaperclipIcon'"
                          size="18"
                        />
                      </b-nav-item>
                    </b-nav>
                  </div>
                  <div>
                    <router-link
                      :to="{
                        name: 'request-detail',
                        params: { code: block.id },
                      }"
                    >
                      <feather-icon
                        :icon="'EyeIcon'"
                        size="18"
                      /></router-link>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    <small class="text-muted">
                      {{ block.requester_name }}
                    </small>
                  </div>
                  <div>
                    <small
                      v-b-tooltip.hover
                      :title="block.requested_time"
                      class="text-muted"
                    >
                      {{ moment(block.requested_time).fromNow() }}
                    </small>
                  </div>
                </div>
              </template>
            </div>
          </kanban-board>
        </div>
      </div>
    </div>
    <!-- End Main Area -->
  </div>
</template>
<style>
.drag-list>li:first-child {
  margin-left: 0 !important;
}

.drag-list>li:last-child {
  margin-right: 0 !important;
}

.board-badges>span {
  margin-right: 0.5rem;
}

.flex-wrap>div {
  margin-top: 0.25rem;
}
</style>
<script>
import axios from 'axios'
import interfaces from '@/templates/_interfaces'
import Loading from '@core/loading/Loading.vue'
import { avatarText } from '@core/utils/filter'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import moment from 'moment'
import _accessApi from '@/router/_accessApi'
import Multiselect from 'vue-multiselect'
import AppTimeline from '@/@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@/templates/AppTimelineItem.vue'

const SUPER_ADMIN_LIST = ['NCT1509059', 'NCT2406030']
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Loading,
    flatPickr,
    vueDropzone: vue2Dropzone,
    quillEditor,
    Multiselect,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      avatarText,
      submitDisabled: false,
      nik: localStorage.getItem('nik'),
      isAdmin: localStorage.getItem('admin'),
      isManager: false,
      employeeList: [],
      employeeEmailList: [],
      employeeListAll: [],
      formData: interfaces.memo,
      isLoading: false,
      isLoadingMain: false,
      previewType: null,
      previewUrl: null,
      searchingVal: null,
      searchingValApp: null,
      searchingValTitle: null,
      searchingValRequester: null,
      searchingValDate: null,
      searchingValDueDate: null,
      searchingValDesc: null,
      triggerFind: false,
      titleCommentHistory: null,
      flatpickconfig: {
        altFormat: 'd-M-Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: false,
        minDate: 'today',
      },
      flatpickReqconfig: {
        mode: 'range',
        altFormat: 'd-M-Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: false,
      },
      listPriority: [],
      listapplication: [],
      listapplicationPurpose: [],
      selectedCC: [],
      listComment: [],
      isLoadingPrivewComment: false,
      showPurpose: false,
      assignedTo: [
        { avatars: '', fullName: 'Agi Tahaja' },
        { avatars: '', fullName: 'Agi Tahaja' },
      ],
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
          ],
        },
      },
      errAttachment: {
        fileInputP: null,
      },
      dropzoneOptions: {
        url: `${axios.defaults.baseURL}upload/add-assets`,
        thumbnailWidth: 150,
        maxFilesize: 20,
        paramName: 'attachment',
        disablePreviews: true,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: 'image/*,application/pdf,.xls,.xlsx,.csv',
        headers: {
          'Cache-Control': '',
        },
      },
      req_type: '',
      config: {
        accepts(block, target, source) {
          return false
        },
        moves(el, source, handle, sibling) {
          return false // elements are always draggable by default
        },
      },
      stages: [
        'open',
        'in-progress',
        'needs-review',
        'freeze',
        'rejected',
        'finish',
      ],
      details: null,
      statusFlag: null,
      blocks: [],
    }
  },
  watch: {
    $route(to, from) {
      // using logic here
      if (to.name === 'change-request') {
        this.req_type = 'Change Request'
        this.statusFlag = 1
      }
      if (to.name === 'memo') {
        this.req_type = 'Memo'
        this.statusFlag = 2
      }
      if (to.name === 'bugs') {
        this.req_type = 'Bugs'
        this.statusFlag = 3
      }
      this.init()
    },
  },

  mounted() {
    this.isManager = SUPER_ADMIN_LIST.includes(this.nik)
    const route = this.$route.path.split('/')
    if (route[1] === 'memo') {
      this.req_type = 'Memo'
      this.statusFlag = 2
    }
    if (route[1] === 'change-request') {
      this.req_type = 'Change Request'
      this.statusFlag = 1
    }
    if (route[1] === 'bugs') {
      this.req_type = 'Bugs'
      this.statusFlag = 3
    }
    this.init()
  },
  methods: {
    resetFilter() {
      this.searchingVal = null
      this.searchingValApp = null
      this.searchingValTitle = null
      this.searchingValRequester = null
      this.searchingValDate = null
      this.searchingValDueDate = null
      this.searchingValDesc = null
      this.searching()
    },
    cleantext(txt) {
      const ret = String(txt).split(' ')
      return `${ret[0]} ${ret[1]}`
    },
    init() {
      this.searchingVal = null
      this.searchingValApp = null
      this.searchingValTitle = null
      this.searchingValRequester = null
      this.searchingValDate = null
      this.searchingValDueDate = null
      this.searchingValDesc = null
      this.getData()
      // console.log(axios.defaults.baseURL)
    },
    moment(date) {
      return moment(date)
    },
    getData() {
      const params = {}
      if (Number(this.isAdmin) !== 1) {
        params.requested_by = this.nik
      } else if (!this.isManager) {
        params.assigned_to = this.nik
      }
      this.isLoadingMain = true
      // get flag from params
      axios
        .get(`/request/list/${this.statusFlag}`, {
          params,
        })
        .then(response => {
          this.blocks = response.data.data.map(item => ({
            id: item.request_code,
            purpose_name: item.purpose_name,
            status: item.status_name,
            priority: item.priority_name,
            application_name: item.application_name,
            title: item.title,
            description: item.description,
            comment_count: item.comment_count,
            priority_color: item.priority_color,
            status_name: item.status_name,
            due_date: item.due_date,
            requested_time: item.requested_time,
            attachment: item.attachment,
            request_code: item.request_code,
            // requester_name short name e.g : John Doe => John D. trim space at the end
            requester_name: item.requester_name
              .trim()
              .split(' ')
              .map((data, index) => {
                if (index === 0 || index === data.length - 1) {
                  return data
                }
                return `${data[0]}.`
              })
              .join(' '),
          }))

          this.assignedTo = response.data.assignedTo

          this.stages = response.data.request_status.map(item => item.name)
          // console.log(this.blocks)
        })
        .catch(error => {
          // console.log(error)
        })
        .finally(() => {
          this.isLoadingMain = false
        })
    },
    getEmployeeDropdown() {
      this.isLoading = true
      axios
        .get('dropdown/nik')
        .then(response => {
          this.employeeList = response.data.data
        })
        .catch(error => {
          // console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getAllEmployeeDropdown() {
      this.isLoading = true
      axios
        .get('dropdown/all_employee')
        .then(response => {
          this.employeeListAll = response.data.data
        })
        .catch(error => {
          // console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getEmployeeEmailDropdown() {
      this.isLoading = true
      axios
        .get('dropdown/employee-email')
        .then(response => {
          this.employeeEmailList = response.data.data
        })
        .catch(error => {
          // console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    get_dropdown() {
      axios
        .get('dropdown/list')
        .then(response => {
          this.listPriority = response.data.priority_status
          this.listapplication = response.data.data
        })
        .catch(error => { })
        .finally(() => { })
    },
    get_application_id(val) {
      this.formData.application_purpose = null
      axios
        .get(`dropdown/application-purpose/${val}`)
        .then(response => {
          if (response.data.data.length > 0) {
            this.listapplicationPurpose = response.data.data
            this.showPurpose = true
          } else {
            this.showPurpose = false
          }
        })
        .catch(error => { })
        .finally(() => { })
    },
    updateBlock(id, status) {
      this.blocks.find(b => b.id === Number(id)).status = status
    },
    attachment(path) {
      if (path === null) return
      this.previewUrl = `${_accessApi.localhost.upload_path_preview}${path}`
      if (
        path.toLowerCase().match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/) != null
      ) {
        this.previewType = 'image'
        this.$refs.previewModal.show()
      } else if (path.toLowerCase().match(/\.(pdf)$/) != null) {
        this.previewType = 'pdf'
        this.$refs.previewModal.show()
      } else {
        window.open(this.previewUrl, '_blank')
      }
    },
    addFormRequest() {
      this.get_dropdown()
      this.getEmployeeDropdown()
      this.getEmployeeEmailDropdown()
      this.formData.flag = this.statusFlag
      this.$refs.addModalForm.show()
    },
    closeFormRequest() {
      this.resetForm()
      this.$refs.addModalForm.hide()
    },
    resetForm() {
      for (const i in interfaces.memo) {
        interfaces.memo[i] = null
      }
      this.formData = interfaces.memo
      this.showPurpose = false
    },
    searchFormRequest() {
      this.get_dropdown()
      this.getAllEmployeeDropdown()
      this.formData.flag = this.statusFlag
      this.$refs.searchModalForm.show()
    },
    submitAdd() {
      this.$refs.customerTitle.validate().then(success => {
        if (success) {
          this.submitDisabled = true
          this.$swal({
            title: 'Add Request',
            text: 'Are you sure want to Add this request ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1',
            },
          }).then(result => {
            if (result.value) {
              this.formData.cc_email_list = this.selectedCC.map(
                item => item.value,
              )
              this.isLoading = true
              axios
                .post('request/add', this.formData)
                .then(response => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Add Request Successful',
                      icon: 'EditIcon',
                      variant: 'success',
                      text: response.data.message,
                    },
                  })
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Add Request Failed',
                      icon: 'EditIcon',
                      variant: 'danger',
                      text: error.message,
                    },
                  })
                })
                .finally(() => {
                  this.isLoading = false
                  this.$refs.addModalForm.hide()
                  this.resetForm()
                  this.init()
                })
            }
          })
        }
      })
    },
    searching() {
      const params = {}
      if (Number(this.isAdmin) !== 1) {
        params.requested_by = this.nik
      } else if (!this.isManager) {
        params.assigned_to = this.nik
      }
      params.flag = this.statusFlag
      params.searching = this.searchingVal
      params.searchApp = this.searchingValApp
      params.searchTitle = this.searchingValTitle
      params.searchRequester = this.searchingValRequester
      params.searchDate = this.searchingValDate
      params.searchDue = this.searchingValDueDate
      params.searchDesc = this.searchingValDesc
      this.isLoadingMain = true
      // get flag from params
      axios
        .post('/request/searching', params)
        .then(response => {
          this.blocks = response.data.data.map(item => ({
            id: item.request_code,
            purpose_name: item.purpose_name,
            status: item.status_name,
            priority: item.priority_name,
            application_name: item.application_name,
            title: item.title,
            description: item.description,
            comment_count: item.comment_count,
            priority_color: item.priority_color,
            status_name: item.status_name,
            due_date: item.due_date,
            requested_time: item.requested_time,
            attachment: item.attachment,
            request_code: item.request_code,
            requester_name: item.requester_name
              .trim()
              .split(' ')
              .map((data, index) => {
                if (index === 0 || index === data.length - 1) {
                  return data
                }
                return `${data[0]}.`
              })
              .join(' '),
          }))
          this.assignedTo = response.data.assignedTo
          this.stages = response.data.request_status.map(item => item.name)
        })
        .catch(error => { })
        .finally(() => {
          this.isLoadingMain = false
        })
    },
    uploadFileRequestSuccess(file, response) {
      this.errAttachment.file = null
      this.formData.attachment = response.path
      this.submitDisabled = false
    },
    sendingFileRequestEvent(file, xhr, formData) {
      this.submitDisabled = true
      formData.append('folder_name', 'request')
    },
    errorFileRequestEvent(file, error, xhr) {
      alert(error)
      this.formData.attachment = null
      this.$refs.myFileP.removeAllFiles()
    },
    completeFileRequestEvent(param) {
      if (param.status === 'error') {
        this.$refs.myFileP.removeAllFiles()
      }
    },
    removeFileRequest(file, error, xhr) {
      if (this.submitDisabled) return
      if (this.formData.attachment != null) {
        axios
          .post('upload/remove-image', { path: this.formData.attachment })
          .then(response => {
            this.formData.attachment = null
          })
          .finally(() => {
            this.isLoading = false
          })
      }
      this.submitDisabled = false
    },
    showComment(request_code) {
      this.$refs.previewCommentModal.show()
      this.isLoadingPrivewComment = true
      axios
        .get(`/request/detail/${request_code}`)
        .then(response => {
          this.listComment = response.data.comments
          this.titleCommentHistory = response.data.data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
          if (error.response.data.status === 4000) {
            this.$router.push({ name: 'dashboard' })
          }
        }).finally(() => {
          this.isLoadingPrivewComment = false
        })
    },
  },
  watchEffect() {
    // const { flag } = this.$route.query
    // this.statusFlag = Number(flag)
  },
}
</script>

<style>
.nav-link {
  padding: 0.2rem 0.2rem;
}

.drag-container {
  max-width: none;
}

.drag-column {
  background: rgb(185 181 181 / 14%);
}

.drag-item {
  background: #fff;
  border-radius: 5px;
  border: linen;
  color: #000;
  height: auto;
}

/*.drag-container {
  max-width: none;
  overflow-x: scroll;
}
.drag-column {
  background: rgb(0 0 0 / 0%);
  min-width: 350px;
  min-height: 500px;
  height: auto;
}
.drag-column h2 {
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
}
/* .drag-inner-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 750px;
} */

/*.drag-item {
  background: none;
  border-radius: 14px;
  color: #000;
  border: solid;
}*/
</style>
